@import '~@estimateone/frontend-components/src/css/variables';

.companyQualification {
  padding: 2px 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #f7f7f7;
  color: #666666;
  font-weight: bold;
  gap: 4px;
}
