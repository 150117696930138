@import '~@estimateone/frontend-components/src/css/variables';

.qualificationsContent {
  display: flex;
}

.showHideDetails {
  padding-left: $space-16;
  font-size: $font-size-sm;
}
