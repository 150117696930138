@import '~@estimateone/frontend-components/src/css/variables';

.companyComplianceDetails {
  margin-top: $space-16;
  padding: $space-16;
  border-radius: 8px;
  background-color: $iron-025;
}

.companyComplianceLearnMore {
  color: $iron-600;
  font: $font-tiny-reg
}

.detailsCard {
  margin-bottom: $space-12;
  padding: 0;
  border-width: 0;
  background-color: $iron-025;
  gap: $space-4 $space-24;

  p {
    margin-block-end: 0;
  }

  dt {
    width: 100px;
    font: $font-ps-med;
  }

  .qualificationDescription {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    img {
      width: 60px;
      height: 60px;
    }
  }
}

.type {
  color: $practical-900;
  font: $font-ps-heavy;
}

.highlight {
  color: $practical-900;
  font: $font-ps-med;
}

