@import '~@estimateone/frontend-components/src/css/variables';

.stage-invite {
  .filters {
    display: flex;
    justify-content: space-between;
    gap: $space-16;
  }

  .filter-item {
    // min-width prevents the dropdown from expanding lengthwise,
    // altering the size of other inputs in the flex container
    min-width: 0;
    flex: 0.887;

    &-long {
      flex: 1.168;
    }
  }
}

.qualifications {
  img {
    padding-left: $space-8;
  }
}
