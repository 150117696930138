form.mark-as-lost-rfq-form {
  .full-width-row {
    margin-top: 10px;
    margin-right: -27px;
    margin-left: -27px;
    box-shadow:
      inset 0 6px 5px -5px rgb(0 0 0 / 27%),
      inset 0 -6px 5px -5px rgb(0 0 0 / 27%);

    .scrollable-contact-list {
      max-height: 350px;
      padding-right: 27px;
      padding-bottom: 10px;
      padding-left: 27px;
      overflow-y: scroll;
      border-top: 1px solid rgb(184 195 211 / 20%);
      border-bottom: 1px solid rgb(184 195 211 / 20%);

      .scrollbox-checkbox {
        float: left;
        width: 50%;
        margin-bottom: 0;

        .checkbox {
          margin-bottom: 5px;

          label {
            font-weight: bold;

            span.company-name {
              display: block;
              color: $iron-500;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}

.invitations-table thead {
  height: 50px;
}

.sf-tooltip {
  padding: 8px;
  border-radius: 3px;

  ul {
    margin: 0 12px;
    padding: 0;
  }
}

.specifinder-icon {
  width: 24px;
  height: 24px;
  background-image: url('#{$image-path}/specifinder-check-icon.svg');
}

.specifinder-icon-inverted {
  width: 24px;
  height: 24px;
  background-image: url('#{$image-path}/specifinder-check-icon-inverted.svg');
}

.rocket-icon {
  width: 56px;
  height: 56px;
  background-image: url('#{$image-path}/rocket.svg');
}

.edit-keywords {
  margin-left: $space-8;
  color: $practical-900;
}

.rfq-slider {
  .e1-logo {
    height: 30px;
  }

  a.view-keywords {
    color: $white;
    text-decoration: underline;
  }

  table.keywords-table {
    .no-hover {
      background-color: $white;

      &:hover {
        background-color: $white;
      }
    }
  }
}

.rfq-slider__btn-container {
  margin-top: $spacer-3;
  display: flex;

  .btn-block {
    + .btn-block {
      margin-top: 0;
      margin-left: $spacer-5;
    }
  }
}

.rfq-slider-header__procurement-message {
  p {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 19px;
    font-weight: 600;
    line-height: 125%;
  }

  .awarded-project-icon {
    margin-right: 8px;
  }
}

p.project-details_procurement-message {
  margin: 24px 0;

  strong {
    font-weight: 600;
  }
}

.invite-calendar {
  margin-left: 32px;
}

.calendar-display {
  @include date-display;
}

.invite_controls .btn {
  margin-top: 1.7rem;
}

.package-control-container {
  max-width: 350px;

  &.has-warning {
    .help-block {
      visibility: visible;
      color: $energy-800;
    }

    .control-label,
    .select2-selection__rendered {
      color: $energy-800;
    }

    .select2-selection--single {
      border-color: $energy-600;
      background-color: $energy-100;
    }
  }

  .help-block {
    visibility: hidden;
    font-size: small;
  }
}

.procurement-letbydate-info {
  margin-top: -56px;
  visibility: hidden;
  font: $font-tiny-reg;
  text-align: right;

  &.show-info {
    visibility: visible;
  }
}

.package-date-picker-container {
  &.has-error {
    .date-picker-validation-error {
      visibility: visible;
      color: red;
    }
  }

  .date-picker-validation-error {
    visibility: hidden;
    font: $font-tiny-heavy;
  }
}

.no-keywords-specified-container {
  margin-top: $space-24;
  padding: $space-32 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: dashed $space-2 $iron-100;
  text-align: center;
}

.interest-levels-dropdown.invitation-dropdown {
  .dropdown-menu li > a {
    padding: $space-8 $space-24;
    color: $primary-practical-blue;

    &:hover {
      background-color: $iron-025;
    }
  }

  .dropdown-menu li.notQuotingReasonLabel {
    padding: $space-16 $space-24 $space-4 $space-24;
    color: $iron-400;
    font: $font-tiny-heavy;
    text-transform: uppercase;
  }

  .dropdown-menu li.notQuotingReasonOption > a {
    padding: $space-8 $space-24 $space-8 $space-32;
  }

  .dropdown-menu li.notQuotingReasonOption > a.active {
    padding: $space-8 $space-12;
    display: flex;
    align-items: center;
    align-self: stretch;
    background-color: $magic-050;

    &::before {
      @extend .icon;
      @extend .icon-check-mini;
      margin-right: $space-4;
      font-size: $font-size-xs;
    }
  }

  .dropdown-menu li.notQuotingReasonOption > a.not-sending {
    font-style: italic;
  }
}

.not-quoting-reason-message {
  margin-left: $space-48;
}

.download-docs-container {
  position: relative;
  display: inline-block;
}

ul.download-docs-menu {
  width: 100%;

  li > a {
    padding-top: $space-8;
    padding-bottom: $space-8;
    color: $primary-practical-blue;
  }
}
